<template>
  <div class="card">
    <a-button
      type="dashed"
      size="large"
      icon="book"
      @click="showModal"
      style="margin-bottom: 20px"
    >
      {{ $t("action.ajouter") }}
    </a-button>
    <a-modal
      v-model="visibleModal"
      @cancel="handleCancel"
      :title="$t('all.enterInformationBelow')"
      :width="600"
      :height="1200"
      :footer="false"
    >
      <a-form :form="form" class="container" @submit="addType">
        <div class="row">
          <div class="col-md-12">
            <a-form-item :label="$t('scolarite.nom')">
              <a-input
                style="width: 100%"
                v-decorator="[
                  'name',
                  {
                    rules: [
                      {
                        required: true,
                        message: $t('requis.nom'),
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </div>
        </div>
        <div class="form-actions mt-0">
          <a-button
            style="width: 150px"
            type="primary"
            htmlType="submit"
            :loading="loadingClasse"
            :disabled="loadingClasse"
            class="mr-3"
          >
            {{ $t("action.ajouter") }}
          </a-button>
          <a-button key="back" @click="handleCancel" :disabled="loadingClasse">
            {{ $t("action.annuler") }}
          </a-button>
        </div>
      </a-form>
    </a-modal>
    <a-table
      :loading="tableLeading"
      :rowKey="'_id'"
      :columns="columns"
      :data-source="data"
      :pagination="true"
      :scroll="{ x: 'max-content' }"
    >
      <template v-for="col in ['name']" :slot="col" slot-scope="text, record">
        <div :key="col">
          <a-input
            v-if="record.editable"
            :value="text"
            @change="(v) => handleChange(v.target.value, record._id, col)"
          />
          <template v-else>
            <div>{{ text }}</div>
          </template>
        </div>
      </template>
      <span slot="operation" slot-scope="text, record">
        <div class="editable-row-operations">
          <span v-if="record.editable">
            <a-button
              size="small"
              type="primary"
              @click="() => save(record._id)"
            >
              {{ $t("action.enregistrer") }}
            </a-button>
            <a-button size="small" type="danger">
              <a-popconfirm
                :title="$t('all.sureToCancelAlert')"
                @confirm="() => cancel(record._id)"
              >
                <a> {{ $t("action.annuler") }} </a>
              </a-popconfirm>
            </a-button>
          </span>
          <span v-else>
            <a-button
              type="primary"
              :disabled="editingKey !== ''"
              @click="() => edit(record._id)"
            >
              <a-icon type="edit" />{{ $t("action.modifier") }}
            </a-button>
            <a-popconfirm
              :title="$t('requis.supp')"
              @confirm="() => supp(record._id)"
            >
              <a-button
                class="ml-2"
                type="danger"
                :disabled="editingKey !== ''"
              >
                <a-icon type="delete" />{{ $t("action.supprimer") }}
              </a-button>
            </a-popconfirm>
          </span>
        </div>
      </span>
    </a-table>
  </div>
</template>
<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import moment from "moment";
import { mapState } from "vuex";
var typesData = [];
export default {
  name: "PunishmentTypes",
  computed: mapState(["settings"]),
  beforeMount() {
    this.form = this.$form.createForm(this, { name: "classForm" });
  },
  created() {
    this.$gtag.pageview({
      page_path: this.$route.path,
      page_location: window.location.href,
      page_title: document.title + " - Type de punitions",
    });

    apiClient
      .post("/punishmentType/filter", {
        query: {},
      })
      .then((res) => {
        typesData = [...res.data].map((e) => ({ ...e, key: e._id }));
        this.data = typesData;
        this.cacheData = typesData.map((item) => ({ ...item }));
        this.tableLeading = false;
      })
      .catch((e) => this.$message.error(this.$t("error.aucAnnee")));
  },
  data() {
    return {
      columns: [
        {
          title: this.$t("scolarite.nom"),
          dataIndex: "name",
          key: "name",
          scopedSlots: { customRender: "name" },
        },
        {
          title: this.$t("action.modifier"),
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
          fixed: "right",
        },
      ],
      services: [],
      tableLeading: true,
      visibleModal: false,
      form: this.$form.createForm(this),
      data: null,
      cacheData: null,
      loadingClasse: false,
      editingKey: "",
    };
  },
  methods: {
    moment,
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    addType(e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.loadingClasse = true;

          apiClient
            .put("/punishmentType", { data: values })
            .then((res) => {
              this.$message.success(this.$t("success.typePunitionAjout"));
              this.data.push(res.data);
              this.handleCancel();
            })
            .catch((e) => this.$message.error(this.$t("error.impoAjoutCeType")))
            .finally(() => (this.loadingClasse = false));
        }
      });
      this.loadingClasse = false;
    },
    showModal() {
      this.visibleModal = true;
    },
    handleCancel() {
      this.form.resetFields();
      this.visibleModal = false;
    },
    handleChange(value, key, column) {
      try {
        const newData = [...this.data];
        const target = newData.filter((item) => key === item.key)[0];
        if (target) {
          target[column] = value;
          this.data = newData;
        }
      } catch (error) {
        console.error("error", error);
      }
    },
    edit(key) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      this.editingKey = key;
      if (target) {
        target.editable = true;
        this.data = newData;
      }
    },
    save(key) {
      const newData = [...this.data];
      const newCacheData = [...this.cacheData];
      const target = newData.filter((item) => key === item.key)[0];
      const targetCache = newCacheData.filter((item) => key === item.key)[0];
      const updates = target;
      apiClient
        .patch("/punishmentType/" + key, { data: updates })
        .then(() => {
          if (target && targetCache) {
            delete target.editable;
            this.data = newData;
            Object.assign(targetCache, target);
            this.cacheData = newCacheData;
          }
          this.editingKey = "";
          this.$message.success(this.$t("success.typePunitionMSA"));
        })
        .catch(() => {
          this.$message.error(this.$t("error.modif"));
          this.cancel(key);
        });
    },
    cancel(key) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      this.editingKey = "";
      if (target) {
        Object.assign(
          target,
          this.cacheData.filter((item) => key === item.key)[0]
        );
        delete target.editable;
        this.data = newData;
      }
    },
    supp(key) {
      apiClient
        .delete("/punishmentType/" + key)
        .then(() => {
          this.$message.success(this.$t("success.typePunitionArchiv"));
          const dataSource = [...this.data];
          this.data = dataSource.filter((item) => item.key !== key);
        })
        .catch(() => this.$message.error(this.$t("error.supp")));
    },
  },
};
</script>
<style scoped>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
